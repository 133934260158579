<template>
	<div class="login" v-loading="showLoading">
		<el-card header="水务系统管理后台登录">
			<el-form :model="user" :rules="rules" ref="loginForm">
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="user.phone" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="pwd">
					<el-input v-model="user.pwd" show-password></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="login">登录</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import md5 from 'js-md5';
	import {login} from '../service/main'

	const SALT = 'shenshuishuiwu'

    export default {
        name: 'Login',
        data() {
            return {
                showLoading: false,
                user: {
                    phone: '',
                    pwd: ''
                },
                rules: {
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {min: 11, max: 11, message: '手机号长度11位', trigger: 'blur'}
                    ],
                    pwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 5, max: 20, message: '密码长度5到20位', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            login() {
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.showLoading = true
                        const params = {
                            phone: this.user.phone,
                            pwd: md5(this.user.pwd + SALT)
                        }
                        login(params).then(res => {
                            const {code, msg, data = {}} = res;
                            if (+code === 0) {
                                sessionStorage.setItem('user', JSON.stringify(data))
                                this.$message.success('登录成功')
                                this.$router.push('/home/welcome')
                            } else {
                                this.$message.error(msg);
                            }
                            this.showLoading = false
                        }).catch(err => {
                            this.showLoading = false;
                            const str = typeof err === 'object' ? JSON.stringify(err) : err;
                            this.$message.error(str);
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
	.login {
		display: flex;
		justify-content: center;
		align-items: center;

		.el-card {
			width: 400px;
			margin-top: 200px;
		}
	}
</style>
